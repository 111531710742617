import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import ThankYou from "../components/sections/thankyou"

const IndexPage = () => (
  <Layout>
    <SEO title="Thanks" />
    <Navigation />
    <ThankYou />
    <Footer />
  </Layout>
)

export default IndexPage
